<template>
    <div class="chooseStock">
        <div class="btn-con">
            <span class="btn" @click="choseGB">确定</span>
        </div>
        <div class="table-con table-bg">
            <el-table
                :data="tableData"
                height="100%"
                border
                style="width: 100%"
                stripe
                ref="multipleTable"
                @row-click="rowClick"
            >
                <el-table-column
                    label="选择"
                    width="55"
                >
                    <template slot-scope="scope">
                        <el-radio
                            v-model="radio"
                            :label="scope.row"
                        >
                        </el-radio>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="stock_code"
                    label="料仓编号"
                >
                </el-table-column>
                <el-table-column
                    prop="stock_name"
                    label="料仓名称"
                >
                </el-table-column>
                <el-table-column
                    prop="stock_name"
                    label="料仓名称"
                >
                </el-table-column>
                <el-table-column
                    prop="stock_no"
                    label="料仓号"
                >
                </el-table-column>
                <el-table-column
                    prop="team_name"
                    label="生产机组名称"
                >
                </el-table-column>
                <el-table-column
                    prop="volume_warn_today"
                    label="预警方量"
                >
                </el-table-column>
                <el-table-column
                    prop="volume_theory"
                    label="理论方量"
                >
                </el-table-column>
                <el-table-column
                    prop="volume_max"
                    label="库存容量"
                >
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            radio: '',
            tableData: [],
            tableSelectData: [], // 单选
        };
    },
    watch: {},
    computed: {},
    created() {
        this.getData();
    },
    mounted() {},
    methods: {
        // 获取过磅单可选择料仓集合
        getData() {
            this.$axios
                .post(`/interfaceApi/procurement/balance/choosestock/${this.extr.biid}`)
                .then(res => {
                    if (res) {
                        this.tableData = res;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        rowClick(row) {
            this.radio = row;
        },
        // 确定选择
        choseGB() {
            if (this.radio) {
                this.extr.callBack(this.radio);
                this.$parent.hide();
            } else {
                this.$message.warning('请选择数据');
            }
        },
    },

};
</script>
<style lang="stylus" scoped>
.chooseStock
    float left
    width calc(100% - .2rem)
    margin .1rem
    height calc(100% - .2rem)
    background #fff
    padding .1rem
    .btn-con
        .btn
            padding .05rem .1rem
            text-align center
            background #1577D2
            border-radius .04rem
            display inline-block
            color #ffffff
            cursor pointer
            margin-bottom .1rem
    .table-con
        height calc(100% - .4rem)
        .iconshanchu
            color #D5000F
            cursor pointer
</style>
